import {createSlice} from "@reduxjs/toolkit";


const tagSlice = createSlice({
    name: 'tagSlice',
    initialState: {selectedTag: 'index'},
    reducers: {
        // 修改selectedTag
        setTag(state, action) {
            state.selectedTag = action.payload
        }
    }
})

export const {setTag} = tagSlice.actions
export const {reducer: tagReducer} = tagSlice
