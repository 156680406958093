import {DynamicImport, useRoutesWithMiddleware} from "react-router-middleware-plus"
import {IsLogin} from "./auth";




export default function Router() {
    return useRoutesWithMiddleware([
        {
            path: '/',
            key: 'index',
            element: <DynamicImport element={() => import('./../App')}/>,
            middleware:[IsLogin],
            children: [
                {
                    path: 'daily',
                    key: 'daily',
                    element: <DynamicImport element={() => import('../pages/daily')}/>,
                    middleware: [],
                },
                {
                    path: 'personal',
                    key: 'personal',
                    element: <DynamicImport element={() => import('../pages/personal/personal')}/>,
                },
            ]
        },
        {
            path: '/userInfo',
            key: 'userInfo',
            element: <DynamicImport element={() => import('../pages/personal/userinfo/userinfo')}/>,
            middleware: [],
        },
        {
            path: '/modifyPass',
            key: 'modifyPass',
            element: <DynamicImport element={() => import('../pages/personal/modifyPass/modifyPass')}/>,
            middleware: [],
        },
        {
            path: '/dailyList',
            key: 'dailyList',
            element: <DynamicImport element={() => import('../pages/personal/dailyList/dailyList')}/>,
            middleware: [],
        },
        {
            path: '/login',
            key: 'login',
            element: <DynamicImport element={() => import('../pages/login/login')}/>
        },
        {
            path: '*',
            key: '404',
            element: <DynamicImport element={() => import('../pages/error')}/>,
        }
    ])
}
