import {configureStore} from "@reduxjs/toolkit";
import {tagReducer} from "./slice/tagSlice";



export default configureStore({
    reducer:{
        tag:tagReducer
    }
})
