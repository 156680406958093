import {useNavigate} from "react-router-dom";
import {useEffect} from "react";

// 是否是管理员
export const CheckRole = ({children}) => {
    const navigate = useNavigate();
    // 根据自己的页面，判断处理，async/await异步拉取用户数据即可。
    const isAdmin = localStorage.getItem('role') === 'admin';

    useEffect(() => {
        if (!isAdmin) {
            navigate('/', {replace: true})
        }
    })

    // 通过鉴权:如果是admin账号放行，否则返回null
    return isAdmin ? children : null
}


// 是否登录
export const IsLogin = ({children}) => {
    const navigate = useNavigate();
    const token = sessionStorage.getItem('token')
    useEffect(() => {
        if (!token) {
            navigate('/login', {replace: true})
        }
    })
    return token ? children : null

}
