import React from 'react';
import ReactDOM from 'react-dom/client';
import store from './store'
import {Provider} from "react-redux";
import {HashRouter} from "react-router-dom";
import Router from "./router";
import locale from 'antd/locale/zh_CN';

import 'dayjs/locale/zh-cn';
import {ConfigProvider} from "antd";

const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
    <ConfigProvider locale={locale}>
        <Provider store={store}>
            <HashRouter>
                <Router/>
            </HashRouter>
        </Provider>
    </ConfigProvider>

);

